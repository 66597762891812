.services-hist-list-content {
    margin-bottom: 22px;
}

.services-hist-list {
    margin-top: 12px;
}

.service-tip {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
}

.service-tip.not-edit-location{
    margin-bottom: 0px;
}

.service-tip span {
    font-size: 13px;
    font-weight: bolder;
    margin-left: 6px;
}

.service-tip button {
    font-size: 13px;
    background: transparent;
    border: none;
    color: #009CF5;
    height: 15px;
    margin-left: 6px;
}

.service-tip-content {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 24px;
}

.service-tip-content span {
    font-size: 13px;
}

.service-tip-content ol {
    margin: 0;
    font-size: 12px;
    padding-left: 32px;
    padding-right: 24px;
}

.service-tip-content li {
    margin-bottom: 8px;
}

.service-tip-content .MuiAccordionDetails-root {
    padding: 0px;
}

.service-tip-content .MuiAccordionSummary-content {
    margin: 8px 0px !important;
}

.service-tip-content .MuiButtonBase-root {
    min-height: 32px !important;
    padding: 0 8px;
}

.contact-us-container {
    display: flex;
    flex-direction: row;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    padding: 16px;
    margin-top: 8px;
}

.contact-us-container img {
    width: 100px;
}

.contact-us-content {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contact-us-content span {
    font-size: 13px;
    font-weight: bold;
}
