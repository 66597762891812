.modal-depression-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 16px;
    border-radius: 10px;
    width: 85%;
}

.modal-depression-title-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-depression-title {
    margin: 0;
    margin-bottom: 6px;
}

.modal-depression-subtitle {
    font-size: 12px;
    text-align: center;
    color: #a2a2a2;
}

.modal-depression-vector-content {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-depression-vector {
    width: 200px;
}

.modal-depression-button-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12px;
    margin-top: 12px;
}

.modal-depression-select-content {
    margin-top: 12px;
}