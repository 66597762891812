.paper-user-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
}

.paper-user-content {
    display: flex;
}

.paper-user-contats-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12px;
}

.paper-user-contats-content span {
    font-size: 13px;
    font-weight: bold;
}

.paper-user-contats-content label {
    font-size: 12px;
    color: #A3A3A3;
}