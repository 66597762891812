.modal-finish-service-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 16px;
    border-radius: 10px;
    width: 85%;
}

.modal-finish-service-title-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-finish-service-title-content h2 {
    margin: 0;
    margin-bottom: 6px;
}

.modal-finish-service-title-content span {
    font-size: 12px;
    text-align: center;
    color: #a2a2a2;
}

.modal-finish-service-star-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 6px;
    margin-top: 6px;
}

.modal-finish-service-star {
    padding: 3px;
    border: none;
    border-radius: 50%;
    background: transparent;
}

.modal-finish-service-button-content {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
}