.keeper-button {
    width: 100%;
    padding: 12px;
    border: none;
    background: #009CF5;
    color: white;
    font-size: 13px;
    font-weight: bold;
    border-radius: 5px;
    max-height: 40px;
}

.keeper-button.error {
    background-color: #FF5656;
}

.keeper-button.disabled {
    opacity: 0.6;
}

.keeper-button.secondary {
    background: transparent;
    color: black;
    border: 1px solid #009CF5;
    font-weight: normal;
}

.keeper-button-label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.keeper-button:focus-visible {
    outline: none;
}

