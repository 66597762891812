.vehicle-forms-container {
    height: calc(var(--vh, 1vh) * 100 - 68px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vehicle-forms-vector-content {
    height: 100%;
    display: flex;
    align-items: center;
}

.vehicle-forms-header,
.vehicle-forms-content,
.vehicle-forms-vector-content img {
    width: 100%;
}

.vehicle-forms-title-input {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.vehicle-forms-title-input img {
    margin-right: 8px;
}

.vehicle-forms-title-input span {
    font-size: 13px;
    font-weight: bolder;
}

.vehicle-forms-describe {
    display: flex;
    flex-direction: column;
}

.vehicle-forms-describe p {
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 3px;
    text-align: center;
}

.vehicle-forms-describe button {
    margin: 16px 0px;
    background: transparent;
    border: none;
    color: #009CF5;
    cursor: pointer;
}

.forms-vehicle-type {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8px;
    margin-bottom: 16px;
}

.forms-vehicle-type-buttom {
    background: transparent;
    border: 1px solid #a2a2a2;
    border-radius: 5px;
    padding: 8px;
}

.forms-vehicle-type-buttom.active {
    border-color: #009CF5;
}

.forms-vehicle-result-content {
    margin-left: 10px;
}

.forms-formatted-vehicle {
    font-size: 11px;
    line-height: 0px;
    font-weight: bold;
    color: #009CF5;
}

.vehicle-forms-input-item {
    margin-top: 16px;
}

.vehicle-forms-footer {
    margin-top: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.vehicle-forms-goback {
    color: #009CF5;
    background-color: transparent;
    border: none;
}