.auth-container {
    background-color: #009CF5;
    height: calc(100vh - 32px);
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.auth-brand-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.auth-content {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 22px;
    border-radius: 10px;
    width: calc(100% - 42px);
}

.auth-content h2 {
    margin: 0;
    margin-top: 6px;
}

.auth-forms-content {
    margin-top: 12px;
    width: 100%;
}

.auth-link {
    background: transparent;
    border: none;
    color: #009CF5;
    font-size: 13px;
    margin-top: 24px;
    margin-bottom: 24px;
}

.auth-button-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    width: 100%;
}

.auth-link-content {
    margin-top: 16px;
    margin-bottom: 6px;
}

.auth-button-content span,
.auth-link-content span {
    font-size: 13px;
}

.auth-subtitle {
    font-size: 13px;
}

.auth-signup-user-content {
    width: 100%;
    margin: 12px 0px;
}

.auth-signup-forms-content {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 8px;
    width: 100%;
}

.auth-signup-terms-content {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
}

.auth-signup-terms-content span {
    font-size: 11px;
    text-align: center;
}

.auth-signup-button-content {
    margin-top: 16px;
    width: 100%;
}

.auth-singin-provider-content {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

