.service-proposal-container {
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 8px;
    width: 100%;
    background: transparent;
}

.service-proposal-container:disabled {
    color: black;
}

.service-proposal-container:focus-visible {
    outline: none;
}

.service-proposal-container.active {
    border-color: #009CF5;
    border-width: 2px;
}

.service-proposal-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.service-proposal-avatar-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.service-proposal-avatar-labels {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 6px;
}

.service-proposal-avatar-labels span {
    font-weight: bold;
    font-size: 13px;
}

.service-proposal-avatar-labels label {
    font-size: 10px;
    color: #A3A3A3;
}

.service-proposal-value {
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.service-proposal-value label {
    font-weight: bold;
    font-size: 10px;
}

.service-proposal-value span {
    font-size: 19px;
    font-weight: bold;
    color: #009CF5
}

.service-proposal-button-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8px;
}

.service-proposal-hr {
    border-top: 0.5px solid #E5E5E5;
    margin: 8px;
}

.service-proposal-hr.active {
    border-color: #009CF5;
}

.service-proposal-status-content {
    margin-bottom: 12px;
}

.service-proposal-status-content span {
    font-size: 12px;
    color: #A3A3A3;
}