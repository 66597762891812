.forms-container {
    height: calc(100vh - 24px);
}

.forms-controler-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    justify-content: space-between;
    height: 60px;
    width: calc(100% - 32px);
    padding: 0 16px;
    position: fixed;
    bottom: 0;
    left: 0;
}

.forms-controler-content-goback {
    background-color: transparent;
    border: none;
    color: #009CF5;
}

/*
.forms-content {
    margin-top: 12px;
}

.forms-subtitle {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 24px;
}

.forms-subtitle span {
    font-size: 13px;
}

.forms-subtitle ol {
    margin: 0;
    font-size: 12px;
    padding-left: 32px;
    padding-right: 24px;
}

.forms-subtitle li {
    margin-bottom: 8px;
}

.forms-subtitle .MuiAccordionDetails-root {
    padding: 0px;
}

.forms-subtitle .MuiAccordionSummary-content {
    margin: 8px 0px !important;
}

.forms-subtitle .MuiButtonBase-root {
    min-height: 32px !important;
    padding: 0 8px;
}

.forms-input-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
}

.forms-input-title.not-edit-location{
    margin-bottom: 0px;
}

.forms-input-title span {
    font-size: 13px;
    font-weight: bolder;
    margin-left: 6px;
}

.forms-input-title button {
    font-size: 13px;
    background: transparent;
    border: none;
    color: #009CF5;
    height: 15px;
    margin-left: 6px;
}

.forms-input-item {
    margin-bottom: 12px;
}

.forms-button-content {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 12px;
    background-color: white;
    z-index: 1;
    box-shadow: 1px 1px 4px 0px #b9b9b9;
    width: calc(100% - 24px);
}

.forms-formatted-addresss {
    font-size: 11px;
    line-height: 0px;
    font-weight: bold;
    color: #009CF5;
}

.forms-formatted-addresss-result-content {
    margin-left: 10px;
}

.forms-difficulty-description {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
}

.forms-difficulty-description span {
    font-size: 13px;
}

.forms-difficulty-description-title {
    font-weight: bold;
    margin-bottom: 6px;
}

.forms-difficulty-service-value {
    font-size: 18px !important;
    font-weight: bold;
    margin-top: 6px;
    color: #009CF5;
}

.forms-describe {
    font-size: 12px;
    margin-bottom: 8px;
    margin-top: -8px;
}

.forms-inform-placa {
    display: flex;
    justify-content: center;
    margin-top: 8px;
}

.forms-inform-placa button {
    background: transparent;
    border: none;
    color: #009CF5;
    cursor: pointer;
}


*/