.loading-solicitation-info-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 0px 15px;
}

.loading-solicitation-info-content span {
    font-size: 18px;
    font-weight: bold;
    color: black
}

.loading-solicitation-info-content img {
    margin-bottom: 24px;
}