.modal-confirm-location-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 16px;
    border-radius: 10px;
    width: 85%;
}

.modal-confirm-location-title-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-confirm-location-title-content h2 {
    margin: 0;
    margin-bottom: 6px;
}

.modal-confirm-location-star-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 6px;
    margin-top: 6px;
}

.modal-confirm-location-star {
    padding: 3px;
    border: none;
    border-radius: 50%;
    background: transparent;
}

.modal-confirm-location-button-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12px;
    margin-top: 12px;
}

.modal-confirm-location-formatted-addresss {
    font-size: 13px;
    font-weight: bold;
    color: #009CF5;
    text-align: center;
}

.modal-confirm-location-formatted-addresss-result-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 8px 0px;
    width: 100%;
}