:root {
    --rsbs-backdrop-bg: rgba(0, 0, 0, 0);
}

.bottom-sheet-container div[data-rsbs-backdrop] {
    top: auto !important
}

.bottom-sheet-content {
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bottom-sheet-subtitle {
    font-size: 13px;
    font-weight: bold;
    color: #000;
    margin-bottom: 8px;
}

.bottom-sheet-content-divider {
    border-top: 1px solid #E5E5E5;
    margin: 12px 0px;
    width: 80%;
}

.bottom-sheet-validate-code-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bottom-sheet-validate-code-content label {
    font-size: 18px;
    font-weight: bold;
    color: #009CF5;
    margin-bottom: 6px;
}

.bottom-sheet-validate-code-content span {
    font-size: 12px;
    color: #a2a2a2;
    text-align: center;
}

.bottom-sheet-price-content {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.bottom-sheet-price-content label {
    font-size: 22px;
    font-weight: bold;
    color: #009CF5;
    margin-top: 6px;
}

.bottom-sheet-price-content span {
    font-size: 10px;
    color: #a2a2a2;
}

.bottom-sheet-price-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 3px;
}

.bottom-sheet-price-title img {
    margin-right: 6px;
}

.bottom-sheet-price-title span {
    font-size: 13px;
    font-weight: bold;
    color: #000;
}

.bottom-sheet-button-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;
}

.bottom-sheet-title-content {
    width: 90%;

}

.bottom-sheet-duration-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 3px;
}

.bottom-sheet-duration-title span {
    font-size: 13px;
    font-weight: bold;
    line-height: 13px;
}

.bottom-sheet-duration-title img {
    margin-right: 6px;
}

.bottom-sheet-duration {
    font-size: 13px;
}

.bottom-sheet-duration.bold {
    font-weight: bold;
}