.bottom-sheet-describe {
    font-size: 11px;
}

.bottom-sheet-finish-service-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
}

.bottom-sheet-finish-service-content label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 6px;
}

.bottom-sheet-finish-service-content span {
    font-size: 12px;
    color: #a2a2a2;
}

.bottom-sheet-finish-service-rate-content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 6px;
    margin-top: 12px;
}