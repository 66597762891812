.papaer-services-container {
    border: none;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 70px;
    padding: 0;
    padding-left: 8px;
    overflow: hidden;
}

.papaer-services-container.shadow {
    background-color: white;
    box-shadow: 1px 1px 4px 0px #b9b9b9
}

.papaer-services-label {
    font-size: 13px;
    font-weight: bold;
    text-align: start;
    margin-right: 6px;
    margin-top: 8px;
}