.location-forms-container {
    height: calc(var(--vh, 1vh) * 100 - 68px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.location-forms-content {
    width: 100%;
}

.location-forms-header {
    height: 100%;
    display: flex;
    align-items: center;
}

.location-forms-header img {
    width: 100%;
}

.location-forms-footer {
    margin-top: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.location-forms-title-input {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.location-forms-title-input span {
    font-size: 13px;
    font-weight: bolder;
}

.location-forms-title-input button {
    color: #009CF5;
    background-color: transparent;
    border: none;
    margin-left: 16px;
}

.location-result-content span {
    font-size: 12px;
    line-height: 0px;
    font-weight: bold;
    color: #009CF5;
}

.location-forms-goback {
    color: #009CF5;
    background-color: transparent;
    border: none;
}