.checkout-header {
    padding: 8px;
}

.checkout-describe {
    text-align: center;
    margin-bottom: 8px;
}

.checkout-describe span {
    font-size: 13px;
    font-weight: normal !important;
}

.checkout-payment-methods-content {
    margin-top: 0px;
}

.checkout-payment-methods-content span {
    font-size: 13px;
    font-weight: bolder;
}

.checkout-payment-methods-button-group {
    margin-top: 4px;
    display: grid;
    column-gap: 8px;
    grid-template-columns: repeat(2, 1fr);
}

.checkout-payment-methods-button-item {
    background: transparent;
    border: 1px solid #a2a2a2;
    border-radius: 5px;
    padding: 8px;
}

.checkout-payment-methods-button-item.active {
    border-color: #009CF5;
}

.checkout-payment-methods {
    display: grid;
    row-gap: 6px;
}

.checkout-document-type {
    margin-top: 8px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8px;
}

.checkout-payment-methods-button {
    background-color: transparent;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 8px;
}

.checkout-payment-methods-button.active {
    border-color: #009CF5;
}

.checkout-forms-content {
}

.checkout-forms-content.disabled {
    display: none;
}

.checkout-button-content {
    margin-top: 8px;
}

.checkout-content {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.checkout-container {
    position: fixed;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    top: 35px;
    left: 0;
    padding: 8px;
}

.checkout-input-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8px;
    margin: 8px 0px;
}

.checkout-input-group-address {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 8px;
    margin: 8px 0px;
}

.checkout-input-content {
    margin-top: 6px;
}

.checkout-forms-content span {
    font-size: 13px;
    font-weight: bolder;
}

.checkout-add-card-button {
    background: transparent;
    color: #009CF5;
    border: none;
    padding: 8px;
    cursor: pointer;
}

.checkout-payment-last-cred-cards {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.checkout-payment-pix {
    margin-top: 12px;
}

.checkout-difficulty-description {
    display: flex;
    flex-direction: column;
}

.checkout-difficulty-description span {
    font-size: 13px;
}

.checkout-difficulty-service-value {
    font-size: 18px !important;
    font-weight: bold;
    margin-top: 6px;
    color: #009CF5;
}

.checkout-span-onsite {
    font-weight: initial;
    text-align: center;
}