.lead-forms-container {
    height: calc(var(--vh, 1vh) * 100 - 68px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lead-forms-content {
    width: 100%;
}

.lead-forms-header {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lead-forms-header img {
    width: 90%;
}

.lead-forms-footer {
    margin-top: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lead-forms-title-input {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lead-forms-title-input span {
    font-size: 13px;
    font-weight: bolder;
}

.lead-forms-title-input button {
    color: #009CF5;
    background-color: transparent;
    border: none;
    margin-left: 16px;
}

.lead-result-content span {
    font-size: 12px;
    line-height: 0px;
    font-weight: bold;
    color: #009CF5;
}

.lead-forms-goback {
    color: #009CF5;
    background-color: transparent;
    border: none;
}

.lead-forms-input-item {
    margin-top: 8px;
}

.lead-forms-footer-hr {
    width: 100%;
    background: #A3A3A3;
    margin-top: 16px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1px;
}

.lead-forms-footer-hr::before {
    content: "ou";
    background: white;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #A3A3A3;
}