.payment-success-container {
    height: 100vh;
    background-color: #009CF5;;
}

.payment-success-content {
    height: 50dvh;
    background: white;
    border-radius: 0px 0px 50px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.payment-success-result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}

.payment-success-result h1 {
    color: white;
    margin: 0;
}

.payment-success-result span {
    color: #424242;
    font-weight: bold;
    margin-top: 8px;
    text-align: center;
}