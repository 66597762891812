.bottom-sheet-cancel-title-content {
    display: flex;
    flex-direction: column;
}

.bottom-sheet-cancel-title-content label {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.bottom-sheet-cancel-title-content span {
    font-size: 13px;
    color: #a2a2a2;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 12px;
}

.bottom-sheet-cancel-describe {
    width: 90%;
}

.bottom-sheet-cancel-describe span {
    font-size: 13px;
    color: #a2a2a2;
    text-align: center;
}