:root {
    --rsbs-backdrop-bg: rgba(0, 0, 0, 0);
}

.bottom-sheet-container div[data-rsbs-backdrop] {
    top: auto !important
}

.bottom-sheet-content {
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bottom-sheet-subtitle {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    margin-bottom: 8px;
}