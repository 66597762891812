.paper-provider-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.paper-provider-content {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
}

.paper-provider-content label {
    font-size: 13px;
    font-weight: bold;
}

.paper-provider-content span {
    color: #a2a2a2;
    font-size: 11px;
}