.paper-credit-card-container {
    width: calc(100% - 16px);
    display: flex;
    align-items: center;
    flex-direction: row;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 4px 8px;
    background: transparent;
    width: 100%;
}

.paper-credit-card-container.active {
    border-color: #009CF5;
}

.paper-credit-card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.paper-credit-card-content label {
    color: #a3a3a3;
    font-size: 12px;
    text-transform: capitalize;
    margin-left: 6px;
}

.paper-credit-card-content.active label {
    color: black;
}