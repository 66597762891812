.pix-payment-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pix-payment-qrcode-content {
    border: 2px solid #a2a2a2;
    border-radius: 15%;
    padding: 26px;
}

.pix-payment-summary-content {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pix-payment-container button {
    background: #009CF5;
    color: white;
    font-weight: bold;
    font-size: 14px;
    border:none;
    border-radius: 5px;
    padding: 8px 16px;
}

.pix-payment-summary-content span{
    color: black;
    font-weight: bold;
}

.pix-payment-summary-content label{
    color: #009CF5;
    font-weight: bold;
    font-size: 26px;
    margin-top: 6px;
}

.pix-payment-progressbar-content {
    display: flex;
    align-items: initial;
    margin-top: 16px;
    background-color: gray;
}