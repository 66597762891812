.hist-service-container {
    width: 100%;
    padding: 15px 20px;
    border-radius: 5px;
    border: none;
}

.hist-service-container.shadow {
    background-color: white;
    box-shadow: 1px 1px 4px 0px #b9b9b9
}

.hist-service-title-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.hist-service-title-content label {
    font-size: 18px;
    font-weight: bold;
}

.hist-service-status {
    font-size: 13px;
}

.hist-service-status.canceled {
    color: #FF5656;
}

.hist-service-status.active,
.hist-service-status.new {
    color: #DCCD43;
}

.hist-service-status.finish {
    color: #32CE83;
}

.hist-service-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 10px;
}

.hist-service-subtitle-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.hist-service-subtitle-content img {
    margin-right: 5px;
}

.hist-service-subtitle-content span {
    font-weight: bold;
    font-size: 13px;
}

.hist-service-provider-content {
    display: flex;
    margin-top: 6px;
    align-items: center;
}

.hist-service-provider-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8px;
}

.hist-service-proposals {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.hist-service-provider-info label,
.hist-service-proposals label {
    font-size: 13px;
    font-weight: bold;
}

.hist-service-proposals span {
    background-color: #009CF5;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    font-size: 12px;
    font-weight: bold;
    margin-left: 3px;
    line-height: 12px;
}

.hist-service-provider-info span {
    color: #a2a2a2;
    font-size: 10px;
}

.hist-service-value-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 6px;
}

.hist-service-value-info label {
    font-size: 20px;
    font-weight: bold;
    color: #009CF5
}

.hist-service-value-info span {
    font-size: 10px;
    color: #a2a2a2
}

.hist-service-describe {
    border-top: 1px solid #E5E5E5;
    margin-top: 12px;
    padding-top: 6px;
}

.hist-service-describe span {
    font-size: 12px;
    color: #a2a2a2;
}